<template>
  <div style="margin-bottom: 300px">
    <v-row class="text-center mt-2">
      <v-col cols="12">
        <v-card
          class="mx-auto d-flex align-center justify-center pa-8"
          style="max-width: 700px; height: auto; width: 95%"
        >
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-img
                width="200px"
                height="200px"
                src="../assets/logo/Logo1.jpg"
                alt="logoDragon"
                class="mx-auto"
                style="max-width: 100%"
              />
            </v-col>
            <v-col cols="12" class="mt-n5">
              <span
                :style="
                  $vuetify.breakpoint.smAndUp
                    ? 'font-size: 20px; color: #008080;'
                    : 'font-size: 16px; color: #008080;'
                "
              >
                ระบบกำลังตรวจสอบ กรุณารอซักครู่
              </span>
            </v-col>
            <v-col cols="12" class="mt-n5">
              <span
                style="color: #718096"
                class="text-h6 text-md-h5 text-sm-h6"
              >
                <v-progress-circular
                  indeterminate
                  color="#008080"
                  :size="60"
                  :width="5"
                ></v-progress-circular>
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <DialogLogin :dialog="dialogLogin" @closeDialogLogin="closeDialogLogin" />
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
import DialogLogin from "./Dialog/DialogLogin.vue";

export default {
  components: {
    DialogLogin,
  },

  name: "LineLogin",

  data: () => ({
    loading: false,
    dialogLogin: false,
    userData: [],
    dataLine: [],
  }),

  watch: {},

  computed: {},

  async created() {
    this.getLineQuery();
  },
  mounted() {},

  beforeDestroy() {},

  methods: {
    async getLineQuery() {
      let code = this.$route.query.code;

      if (code) {
        let params = new URLSearchParams();
        params.append("grant_type", "authorization_code");
        params.append("code", code);
        params.append("redirect_uri", "https://mewniyomlottery.com/lineLogin");
        params.append("client_id", "2003677642");
        params.append("client_secret", "ac27a83d05c769e6c781d437b52f6a92");

        try {
          const tokenRequest = await this.axios.post(
            "https://api.line.me/oauth2/v2.1/token",
            params,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );

          const tokenData = tokenRequest.data;

          const profileRequest = await this.axios.get(
            "https://api.line.me/v2/profile",
            {
              headers: {
                Authorization: `Bearer ${tokenData.access_token}`,
              },
            }
          );

          this.dataLine = {
            lineToken: tokenData,
            profile: profileRequest.data,
          };

          this.checkUsers(this.dataLine);
        } catch (error) {
          console.error(error);
        }
      }
    },

    async checkUsers(dataLine) {
      try {
        const dataCheckDup = {
          socialId: dataLine.profile.userId,
        };
        const responseCheckDup = await this.axios.post(
          `${process.env.VUE_APP_API}/users/lineLogin`,
          dataCheckDup
        );

        const userData = {
          token: responseCheckDup.data.data.token,
          user: responseCheckDup.data.data.user,
        };
        if (responseCheckDup.data.data.isNewUser == false) {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "success",
            text: "เข้าสู่ระบบเสร็จสิ้น",
          });
          console.log("userData", userData);
          localStorage.setItem("MEWUser", Encode.encode(userData));
          this.$router.currentRoute.path == "/"
            ? this.$router.go(0)
            : this.$router.push("/").then(() => {
                this.$router.go(0);
              });
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "warning",
            text: "ท่านยังไม่เคยสมัครสมาชิก หรือผูกไลน์ ID กรุณาสมัครสมาชิกก่อน",
          });
          this.$router.currentRoute.path == "/register"
            ? this.$router.go(0)
            : this.$router.push({
                path: "/register",
                query: { socialId: dataLine.profile.userId },
              });
        }
      } catch (error) {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          icon: "warning",
          text: "เกิดข้อผิดพลาดของระบบ กรุณาล็อกอินใหม่",
        });
        this.dialogLogin = true;
      }
    },

    closeDialogLogin() {
      this.dialogLogin = false;
    },
  },
};
</script>

<style scoped>
</style>

